import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import "./Editar.css";
import { adapterForm } from '../../Adapter';
import cityImage from '../../img/Santiago.png';
import Header from '../Header/Header';
import { NextButton } from '../Form//NextButton';
import Question from '../Form/Question';
import { BackButton } from '../Form/BackButton';
import { useNavigate } from 'react-router-dom';
import { nativeSelectClasses } from '@mui/material';


// Función para enviar los datos al backend
const sendDataToBackend = async (token, datos) => {
    try {
        if (datos.profesion[0] !== datos.profesion1) {
            //cambiar el datos.profesion[0] por datos.profesion1
            if (!datos.profesion[0]) {
                datos.profesion[0] = {};  // Creamos un nuevo objeto si no existe
            }
            datos.profesion[0].id = datos.profesion1;
        }
        //datos profesion 2
        if (datos.profesion[1] !== datos.profesion2 || !datos.profesion[1]) {
            if (!datos.profesion[1]) {
                datos.profesion[1] = {};  // Creamos un nuevo objeto si no existe
            }
            datos.profesion[1].id = datos.profesion2;
        }
        // Transforma los nombres de los campos según lo que espera el backend
        const dataToSend = adapterForm(datos); // el adapterForm hace que los datos esten en el formato del back
        // Realiza la solicitud PATCH
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/edit_profile`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(dataToSend),
        });

        if (!response.ok) {
            const errorMessage = await response.text();
            console.error(`Error en la solicitud PATCH: ${errorMessage}`);
        } else {
            // Verificar si la respuesta tiene contenido antes de parsear
            const responseText = await response.text();
            if (responseText) {
                const jsonResponse = JSON.parse(responseText);
                console.log('Datos enviados exitosamente');
            } else {
                console.log('Datos enviados exitosamente, sin respuesta JSON');
            }
        }
    } catch (error) {
        console.error('Error en la solicitud PATCH:', error);
    }
};


//Componente Formulario
const Edit = (step, onCancel) => {
    const [datos, setDatos] = useState({});
    const [currentStep, setCurrentStep] = useState(step.currentStep)
    const datosPaises = JSON.parse(localStorage.getItem('datosPaises'));
    const datosRegion = JSON.parse(localStorage.getItem('datosRegion'));
    const datosProfesiones = JSON.parse(localStorage.getItem('datosProfesiones'));
    const datosUniversidades = JSON.parse(localStorage.getItem('datosUniversidades'));
    const datosCargos = JSON.parse(localStorage.getItem('datosCargos'));
    const datosIndustrias = JSON.parse(localStorage.getItem('datosIndustrias'));
    const aniosExperiencia = JSON.parse(localStorage.getItem('aniosExperiencia'));
    const competencias = JSON.parse(localStorage.getItem('competencias'));
    const areasExperiencia = JSON.parse(localStorage.getItem('areasExperiencia'));
    const disponibilidades = JSON.parse(localStorage.getItem('disponibilidades'));
    const jornadas = JSON.parse(localStorage.getItem('jornadas'));
    const modalidades = JSON.parse(localStorage.getItem('modalidades'));
    const idiomas = JSON.parse(localStorage.getItem('idiomas'));
    const conocio = JSON.parse(localStorage.getItem('conocio'));
    const personalidades = JSON.parse(localStorage.getItem('personalidades'));
    const [selectedPostgrado, setSelectedPostgrado] = useState('');
    const [selectedPueblo, setSelectedPueblo] = useState('');
    const [selectedFactor, setSelectedFactor] = useState('');
    const [selectedCompromiso, setSelectedCompromiso] = useState('');
    const [selectedIndustrias, setSelectedIndustrias] = useState([]);
    const [selectedAreas, setSelectedAreas] = useState([]);
    const [selectedCompetencias, setSelectedCompetencias] = useState([]);
    const [selectedIdiomas, setSelectedIdiomas] = useState([]);
    const [selectedDisponibilidad, setSelectedDisponibilidad] = useState([]);
    const [mostrarOtraSubarea, setMostrarOtraSubarea] = useState(false);
    const [file, setFile] = useState(null);
    const [contacto1, setContacto1] = useState('');
    const [contacto2, setContacto2] = useState('');
    const [contactos, setContactos] = useState([]);
    const [universidad, setUniversidad] = useState(0);
    const [selectedSubareas, setSelectedSubareas] = useState([]); 
    const [Subareas, setSubareas] = useState([])
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDatos(prevState => ({ ...prevState, [name]: value }));
    };

    const findUniversidad = (id) => {
        const universidadNombre = datosUniversidades.find(universidad => universidad.id === parseInt(id, 10));
        return universidadNombre ? universidadNombre.nombre : '';
    };

    const findUniversidadId = (nombre) => {

        const universidadId = datosUniversidades.find(universidad => universidad.nombre === nombre);
        return universidadId ? universidadId.id : '';
    };
    // inputs de select 
    const handleDropdownChange = (event) => {
        const { name, value } = event.target;
        setDatos(prevState => ({ ...prevState, [name]: value }));

        const newValue = value === 'true' ? true : value === 'false' ? false : value;

        if (name === 'selectedPostgrado') {  // Asegúrate de que el nombre coincide
            setSelectedPostgrado(value);
        }
        if (name === 'pueblo') {
            setSelectedPueblo(value);
        }
        if (name === 'selectedFactor') {
            setSelectedFactor(value);
        }
        if (name === 'selectedCompromiso') {    
            setSelectedCompromiso(value);
        }
        if (name === 'universidad') {
            setUniversidad(value);
            setDatos(prevState => ({ ...prevState, universidad: findUniversidad(value) }));
        }
    };

    //enviar formulario
    const handleSubmit = async () => {
        datos.contactos = [contacto1, contacto2];
        const token = localStorage.getItem('jwtToken');
        await sendDataToBackend(token, datos); 
        step.onCancel();
        console.log('Información Actualizada.');

    };
    
    //manejo de los multi-selects
    const handleSelectionChange = (selectedOptions, name) => {
        if (selectedOptions.length > 5) {
            alert('No puedes seleccionar más de 5 opciones');
            return
        }
        if (name === 'industriasExperiencia') {
            setSelectedIndustrias(selectedOptions);
            const industriasSeleccionadas = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, industrias: industriasSeleccionadas }));
        }
        if (name === 'competencias') {
            setSelectedCompetencias(selectedOptions);
            const competenciasSeleccionadas = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, competencia: competenciasSeleccionadas }));
        }
        if (name === 'areas') {
            setSelectedAreas(selectedOptions);
            const areasSeleccionadas = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, areas: areasSeleccionadas }));

        }
        if (name === 'subareas') {
            setSelectedSubareas(selectedOptions);
            const subareasSeleccionadas = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, subareas: subareasSeleccionadas }));
            // Si seleccionan la opción "Otra" (id: 0), habilitamos un campo de texto adicional
            const isOtraSelected = selectedOptions.some(option => option.value === 0);
            setMostrarOtraSubarea(isOtraSelected); // Muestra el campo para escribir "Otra área"
        }
        if (name === 'idiomas') {
            setSelectedIdiomas(selectedOptions);
            const idiomasSeleccionados = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, idiomas: idiomasSeleccionados }));
        }
        if (name === 'disponibilidad') {
            setSelectedDisponibilidad(selectedOptions);
            const disponibilidadSeleccionada = selectedOptions.map(option => ({ id: option.value }));
            setDatos(prevState => ({ ...prevState, disponibilidad: disponibilidadSeleccionada }));
        }

    };

    //manejo de los contactos de referencia
    const handleContactos = (event) =>{
        const {name,value} = event.target;
        setDatos(prevState => ({
            ...prevState,
            [name]: value,
        }));
        if (name === "contacto_nombre1"){
          let copiaContacto = [...contacto1];
          copiaContacto[0] = value;
          setContacto1(copiaContacto);    
        }
        else if(name==="contacto_celular1"){
          let copiaContacto = [...contacto1];
          copiaContacto[2] = value;
          setContacto1(copiaContacto);
        }
        else if(name==="contacto_mail1"){
          let copiaContacto = [...contacto1];
          copiaContacto[1] = value;
          setContacto1(copiaContacto);
        }
        else if(name==="contacto_nombre2"){
          let copiaContacto = [...contacto2];
          copiaContacto[0] = value;
          setContacto2(copiaContacto);
        }
        else if(name==="contacto_celular2"){
          let copiaContacto = [...contacto2];
          copiaContacto[2] = value;
          setContacto2(copiaContacto);
        }
        else if(name==="contacto_mail2"){
          let copiaContacto = [...contacto2];
          copiaContacto[1] = value;
          setContacto2(copiaContacto);
        }
    };
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const responseProfile = await axios.get(
                    `${process.env.REACT_APP_API_URL}/users/get_profile`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
                const profileData = responseProfile.data;
    
                // Set data only if it's not empty or null to avoid overwriting
                setDatos(profileData);
    
                if (profileData.contactos_verificacion && profileData.contactos_verificacion.length > 0) {
                    // Check for first contact (contacto1)
                    if (profileData.contactos_verificacion[0]) {
                        const contacto1Data = profileData.contactos_verificacion[0];
                        setContacto1(contacto1 => [
                            contacto1Data.nombre || contacto1[0],
                            contacto1Data.email || contacto1[1],
                            contacto1Data.telefono || contacto1[2]
                        ]);
                        setDatos(prevState => ({
                            ...prevState,
                            contacto_nombre1: contacto1Data.nombre || prevState.contacto_nombre1,
                            contacto_mail1: contacto1Data.email || prevState.contacto_mail1,
                            contacto_celular1: contacto1Data.telefono || prevState.contacto_celular1,
                        }));
                    }
    
                    // Check for second contact (contacto2)
                    if (profileData.contactos_verificacion[1]) {
                        const contacto2Data = profileData.contactos_verificacion[1];
                        setContacto2(contacto2 => [
                            contacto2Data.nombre || contacto2[0],
                            contacto2Data.email || contacto2[1],
                            contacto2Data.telefono || contacto2[2]
                        ]);
                        setDatos(prevState => ({
                            ...prevState,
                            contacto_nombre2: contacto2Data.nombre || prevState.contacto_nombre2,
                            contacto_mail2: contacto2Data.email || prevState.contacto_mail2,
                            contacto_celular2: contacto2Data.telefono || prevState.contacto_celular2,
                        }));
                    }
                }
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        };
        fetchData();
        const fetchSubareas = async () => {
            try {
                const token = localStorage.getItem('jwtToken');
                const responseSubareas = await axios.post(
                    `${process.env.REACT_APP_API_URL}/obtener_listas/subareas`,
                    { areas: selectedAreas.map(area => area.value) }, // Filtras por áreas seleccionadas
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }
                );
    
                // Almacenar subáreas obtenidas en el estado
                const subareasObtenidas = responseSubareas.data;
                setSubareas([{ id: 0, nombre: 'Otra' }, ...subareasObtenidas]); // Agregar 'Otra' como primera opción
            } catch (error) {
                console.error('Error al obtener subáreas:', error);
            }
        };
        fetchSubareas();
    }, []);

    //actualizar datos que se muestran en el formulario (la primera vez)
    if (datos.paisDomicilio !== null  && datos.paisDomicilio !== undefined && !datos.id_pais_domicilio) {
        datos['id_pais_domicilio'] = datos.paisDomicilio.id;
    }
    if (datos.regionActualDomicilio !== null && datos.regionActualDomicilio !== undefined && !datos.region_domicilio) {
        datos['region_domicilio'] = datos.regionActualDomicilio.id;
    }
    if (datos.profesion && datos.profesion.length > 0 && !datos.profesion1) {
        datos['profesion1'] = datos.profesion[0].id;
    }
    if (datos.profesion && datos.profesion.length > 1 && !datos.profesion2) {
        datos['profesion2'] = datos.profesion[1].id;
    }
    if (datos.postgrado !== null && datos.postgrado !== undefined && !selectedPostgrado && datos.postgrado !== '') {
        setSelectedPostgrado(datos.postgrado ? '1' : '0');
    }
    if (datos.cargo && datos.cargo.id && !datos.id_cargo) {
        datos['id_cargo'] = datos.cargo.id;
    }
    if (datos.aditionalCargo && datos.aditionalCargo.id && !datos.id_cargo_adicional) {
        datos['id_cargo_adicional'] = datos.aditionalCargo.id;
    }
    if (datos.industria && datos.industria.id && !datos.id_industria_actual) {
        datos['id_industria_actual'] = datos.industria.id;
    }
    if (datos.aditionalIndustria && datos.aditionalIndustria.id && !datos.id_industria_adicional) {
        datos['id_industria_adicional'] = datos.aditionalIndustria.id;
    }
    if (datos.aniosExperiencia && datos.aniosExperiencia.id && !datos.id_anios_experiencia) {
        datos['id_anios_experiencia'] = datos.aniosExperiencia.id;
    }
    if (datos.tipoJornada && datos.tipoJornada.id && !datos.id_jornada) {
        datos['id_jornada'] = datos.tipoJornada.id;
    }
    if (datos.tipoModalidad && datos.tipoModalidad.id && !datos.id_modalidad) {
        datos['id_modalidad'] = datos.tipoModalidad.id;
    }
    if (datos.conocioWOT && datos.conocioWOT.id && !datos.id_conocio_wot) {
        datos['id_conocio_wot'] = datos.conocioWOT.id;
    }
    if (datos.personalidad && datos.personalidad.id && !datos.id_personalidad) {
        datos['id_personalidad'] = datos.personalidad.id;
    }
    if (datos.areas && datos.areas.length > 0 && selectedAreas.length === 0) {
        const areasSeleccionadas = datos.areas.map(area => ({
            value: area.id,
            label: area.nombre
        }));
        
        setSelectedAreas(areasSeleccionadas);
    }
    if (datos.subareas && datos.subareas.length > 0 && selectedSubareas.length === 0) {
        const areasSeleccionadas = datos.subareas.map(subarea => ({
            value: subarea.id,
            label: subarea.nombre
        }));
        if (datos.otrasSubareas) {
            areasSeleccionadas.push({
                value: 0,
                label: "Otra"
            });
            setMostrarOtraSubarea(true);
        }
        
        setSelectedSubareas(areasSeleccionadas);

        
    }
    if (datos.competencia && datos.competencia.length > 0 && selectedCompetencias.length === 0) {
        const competenciasSeleccionadas = datos.competencia.map(competencia => ({
            value: competencia.id,
            label: competencia.competencia
        }));
        setSelectedCompetencias(competenciasSeleccionadas);
    }
    if (datos.idiomas && datos.idiomas.length > 0 && selectedIdiomas.length === 0) {
        const idiomasSeleccionados = datos.idiomas.map(idioma => ({
            value: idioma.id,
            label: idioma.nombre
        }));
        setSelectedIdiomas(idiomasSeleccionados);
    }
    if (datos.industrias && datos.industrias.length > 0 && selectedIndustrias.length === 0) {
        const industriasSeleccionadas = datos.industrias.map(industria => ({
            value: industria.id,
            label: industria.nombre_industria
        }));
        setSelectedIndustrias(industriasSeleccionadas);
    }
    if (datos.disponibilidad && datos.disponibilidad.length > 0 && selectedDisponibilidad.length === 0) {   
        const disponibilidadSeleccionada = datos.disponibilidad.map(disponibilidad => ({
            value: disponibilidad.id,
            label: disponibilidad.disponibilidad
        }));
        setSelectedDisponibilidad(disponibilidadSeleccionada);
    }
    if ( datos.aditionalCrgo || datos.aditionalIndustria || datos.empresa_adicional) {
        datos['rolAdicional'] = '1';
    }
    if (datos.posibilidadCambiarseRegion && datos.posibilidadCambiarseRegion.id &&  !datos.id_posibilidad_cambiarse_region) {
        datos['id_posibilidad_cambiarse_region'] = datos.posibilidadCambiarseRegion.id;
    }
    if (datos.regionCompromiso && datos.regionCompromiso.id && !datos.id_region_con_compromiso) {
        datos['id_region_con_compromiso'] = datos.regionCompromiso.id;
    }
    if (datos.id_region_con_compromiso && datos.id_region_con_compromiso !== null && !selectedCompromiso) {
        setSelectedCompromiso('Si');
    }
    if (datos.factor !== null && datos.factor !== undefined && !selectedFactor) {
        setSelectedFactor('Si');
    }
    if (datos.nombrePuebloOriginario !== null &&  datos.nombrePuebloOriginario !==  undefined  && !selectedPueblo) {
        setSelectedPueblo('Si');
    }
    if (datos.contactos_verificacion && datos.contactos_verificacion.length > 0 && contactos.length === 0) {
        if (datos.contactos_verificacion[0].nombre) {
            datos['contacto_nombre1'] = datos.contactos_verificacion[0].nombre;
            datos['contacto_mail1'] = datos.contactos_verificacion[0].email;
            datos['contacto_celular1'] = datos.contactos_verificacion[0].telefono;
        }
        if (datos.contactos_verificacion[1].nombre) {
            datos['contacto_nombre2'] = datos.contactos_verificacion[1].nombre;
            datos['contacto_mail2'] = datos.contactos_verificacion[1].email;
            datos['contacto_celular2'] = datos.contactos_verificacion[1].telefono;
        }
    }
    if (datos.universidad  && currentStep === 2 && universidad === 0) {
        const idUniversidad = findUniversidadId(datos.universidad);
        setUniversidad(idUniversidad);
    }
    //MANEJA TODAS LAS PREGUNTAS DEL FORMULARIO
    const questions = [
        {
            step: 2,
            label: "Profesión",
            description: "Elige la opción que más se acomode a tu profesión",
            type: "select",
            name: "profesion1",
            value: datos.profesion1 || '',
            options: datosProfesiones.map((profesion) => ({
                value: profesion.id,
                label: profesion.nombre
            })),
            isFieldEmpty: !datos.profesion1,
            onChange: handleDropdownChange,
        },
        {
            step: 2,
            label: "Segunda Profesión",
            description: "Selecciona otra profesión si es que tienes", 
            type: "select",
            name: "profesion2",
            value: datos.profesion2 || '',
            options: datosProfesiones.map((profesion) => ({
                value: profesion.id,
                label: profesion.nombre
            })),
            isFieldEmpty: !datos.profesion2,
            onChange: handleDropdownChange,
        },
        {
            step: 3,
            label: "Universidad",
            description: "Selecciona tu universidad de pregrado",
            type: "select",
            name: "universidad",
            value: universidad || '',
            options: datosUniversidades.map((universidad) => ({
                value: universidad.id,
                label: universidad.nombre
            })),
            isFieldEmpty: universidad,
            onChange: handleDropdownChange,
        }, 
        {
            step: 4,
            label: "Postgrado",
            description: "Se considera como tal al que acredita la obtención de un grado académico equivalente a magíster o doctorado, otorgado por una universidad reconocida por el Estado, nacional o extranjera.",
            type: "select",
            name: "selectedPostgrado",
            value: selectedPostgrado ,
            options: [
                { value: '1', label: 'Sí' },
                { value: '0', label: 'No' },
            ],
            isFieldEmpty: !selectedPostgrado,
            onChange: handleDropdownChange,
        }, 
        {
            step: 4,
            label: "Qué postgrado tienes", 
            description: "Indicar título y universidad", 
            name: "postgrado",
            value: datos.postgrado || '',
            isFieldEmpty: !datos.postgrado,
            isVisible: selectedPostgrado === '1', //ver si se va a guardar como si o no 
        },
        {
            step:5, 
            label: "Cargo actual",
            description: "Elige la opción que más se acomode a tu cargo. Si no tienes cargo selecciona \"Ninguno\"",
            type: "select",
            name: "id_cargo",
            value: datos.id_cargo || '',
            options: datosCargos.map((cargo) => ({
                value: cargo.id,
                label: cargo.cargo
            })),
            isFieldEmpty: !datos.id_cargo,
            onChange: handleDropdownChange,
        },
        {
            step:5, 
            label: "Empresa actual",
            name: "empresa_actual",
            value: datos.empresa_actual || '',
            isFieldEmpty: !datos.empresa_actual,
        },
        {
            step: 5, 
            label: "Industria actual", 
            name: "id_industria_actual",
            type: "select",
            options: datosIndustrias.map((industria) => ({
                value: industria.id,
                label: industria.nombre_industria
            })),
            value: datos.id_industria_actual || '',
            isFieldEmpty: !datos.id_industria_actual,
            onChange: handleDropdownChange,
        }, 
        {
            step: 6,  
            label: "Cargo adicional o complementario",
            name: "id_cargo_adicional",
            value: datos.id_cargo_adicional || '',
            type: "select",
            options: datosCargos.map((cargo) => ({
                value: cargo.id,
                label: cargo.cargo
            })),
            isFieldEmpty: !datos.id_cargo_adicional,
            isVisible: datos.rolAdicional === '1',
            onChange: handleDropdownChange,
        },
        {
            step: 6,
            label: "Empresa adicional o complementaria",
            name: "empresa_adicional",
            value: datos.empresa_adicional || '',
            isFieldEmpty: !datos.empresa_adicional,
            isVisible: datos.rolAdicional === '1',
        }, 
        {
            step: 6,
            label: "Industria adicional o complementaria",
            name: "id_industria_adicional",
            value: datos.id_industria_adicional || '',
            type: "select",
            options: datosIndustrias.map((industria) => ({
                value: industria.id,
                label: industria.nombre_industria
            })),
            isFieldEmpty: !datos.id_industria_adicional,
            isVisible: datos.rolAdicional === '1',
            onChange: handleDropdownChange,
        },
        {
            step: 7,
            label: "Años de experiencia",
            name: "id_anios_experiencia",
            value: datos.id_anios_experiencia || '',
            type: "select",
            options: aniosExperiencia.map((anios) => ({
                value: anios.id,
                label: anios.rango
            })),
        },
        {
            step: 8,
            label: "Sector o industria en la que tienes experiencia",
            description: "Seleccionar máximo 5 respuestas",
            name: "industriasExperiencia",
            type: "multi-select",
            value: selectedIndustrias || [],
            options: datosIndustrias.map((industria) => ({
                value: industria.id,
                label: industria.nombre_industria
            })),
            isFieldEmpty: !datos.industriasExperiencia,
            onChange: handleSelectionChange,
            className: 'input-multi-select-editar'
        },
        {
            step: 9,
            label: "Competencias", 
            description: "Seleccionar máximo 5 respuestas , priorizando las fortalezas y talentos que mas te representan",
            name: "competencias",
            type: "multi-select",
            value: selectedCompetencias || [],
            options: competencias.map((competencia) => ({
                value: competencia.id,
                label: competencia.competencia
            })),
            isFieldEmpty: !datos.competencias,
            onChange: handleSelectionChange,
            className: 'input-multi-select-editar'
        },
        {
            step: 10,
            label: "Experiencia en directorios",
            description: "Esto significa ejercer o haber ejercido el rol de directora",
            name: "experienciaDirectorios",
            type: "select",
            options: [
                { value: 'true', label: 'Sí' },
                { value: 'false', label: 'No' },
            ],
            value: datos.experienciaDirectorios !== undefined ? datos.experienciaDirectorios.toString() : '', // Asegúrate de que el valor sea una cadena
            isFieldEmpty: datos.experienciaDirectorios === undefined || datos.experienciaDirectorios === null,
            onChange: handleDropdownChange,
        },
        {
            step: 11, 
            label: "Áreas de experiencia", 
            description: "Seleccionar máximo 5 respuestas. Indica las áreas en las que has trabajado anteriomente",
            name: "areas",
            type: "multi-select",
            value: selectedAreas || [],
            options: areasExperiencia.map((area) => ({
                value: area.id,
                label: area.nombre
            })),
            isFieldEmpty: !datos.areas,
            onChange: handleSelectionChange,
            className: 'input-multi-select-editar'
        },
        {
            step: 28, 
            label: "Subáreas de experiencia", 
            description: "Seleccionar máximo 5 respuestas. Indica las áreas en las que has trabajado anteriomente",
            name: "subareas",
            type: "multi-select",
            value: selectedSubareas || [],
            options: Subareas.map((subarea) => ({
                value: subarea.id,
                label: subarea.nombre
            })),
            isFieldEmpty: !datos.subareas,
            onChange: handleSelectionChange,
            className: 'input-multi-select-editar'
        },
        {
            step: 28,
            label: "Otra subárea de experiencia",
            description: "Escribe el nombre de la otra subárea de experiencia",
            name: "otraSubarea",
            type: "text",
            value: datos.otrasSubareas,
            isFieldEmpty: !datos.otrasSubareas,
            isVisible: mostrarOtraSubarea, // Solo se muestra si 'Otra' está seleccionada
            onChange: handleInputChange,
        },
        {
            step: 12, 
            label: "Formación en alta dirección",
            name: "altaDireccion",
            type: "select",
            options: [
                { value: 'true', label: 'Sí' },
                { value: 'false', label: 'No' },
            ],
            value: datos.altaDireccion !== undefined ? datos.altaDireccion.toString() : '', // Convertir el valor a cadena
            isFieldEmpty: datos.altaDireccion === undefined || datos.altaDireccion === null,
            onChange: handleDropdownChange,
        },
        {
            step: 13, 
            label: "Disponibilidad", 
            description: "Seleccionar una o más respuestas Indica las modalidades en que estarías dispuesta asumir un nuevo trabajo, proyecto o asesoría",
            name: "disponibilidad",
            type: "multi-select",
            value: selectedDisponibilidad || [],
            options: disponibilidades.map((disponibilidad) => ({
                value: disponibilidad.id,
                label: disponibilidad.disponibilidad
            })),
            isFieldEmpty: !selectedDisponibilidad,
            onChange: handleSelectionChange,
            className: 'input-multi-select-editar'
        },
        {
            step: 14,
            label: "Posibilidad de cambiarse de región",
            type: "select",
            name: "id_posibilidad_cambiarse_region",
            value: datos.id_posibilidad_cambiarse_region || '',
            options: [
                { value: '1', label: 'Sí' },
                { value: '2', label: 'No' },
            ],
            isFieldEmpty: !datos.id_posibilidad_cambiarse_region,
        },
        {
            step: 15, 
            label: "Disponibilidad de jornada", 
            type: "select",
            name: "id_jornada",
            value: datos.id_jornada || '',
            options: jornadas.map((jornada) => ({  
                value: jornada.id,
                label: jornada.tipoJornada
            })),
            isFieldEmpty: !datos.id_jornada,
            onChange: handleDropdownChange,
        }, 
        {
            step: 16,
            label: "Modalidad de preferencia", 
            type: "select",
            name: "id_modalidad",
            value: datos.id_modalidad || '',
            options: modalidades.map((modalidad) => ({
                value: modalidad.id,
                label: modalidad.tipoModalidad
            })),
            isFieldEmpty: !datos.id_modalidad,
            onChange: handleDropdownChange,
        }, 
        {
            step: 17,
            label: "Disposición a viajar",
            type: "select",
            name: "disposicion_viajar",
            value: datos.disposicion_viajar !== undefined ? datos.disposicion_viajar.toString() : '', // Convertir el valor a cadena
            options: [
                { value: 'true', label: 'Sí' },
                { value: 'false', label: 'No' },
            ],
            isFieldEmpty: datos.disposicion_viajar === undefined || datos.disposicion_viajar === null,
            onChange: handleDropdownChange,
        },
        {
            step: 18,
            label: "Expectativa de renta líquida", 
            name: "expectatviaRenta",
            value: datos.expectatviaRenta || '',
            isFieldEmpty: !datos.expectatviaRenta,
        }, 
        {
            step: 19, 
            label: "Dominio de idioma medio-avanzado", 
            type: "multi-select",
            name: "idiomas",
            value: selectedIdiomas || [],
            options: idiomas.map((idioma) => ({
                value: idioma.id,
                label: idioma.nombre
            })),
            isFieldEmpty: !selectedIdiomas,
            onChange: handleSelectionChange,
            className: 'input-multi-select-editar'
        },
        {
            step: 27, 
            label: "Que herramientas adicionales manejas",
            description: 'Máximo 350 caracteres',
            type: "textarea",
            name: "herramientas",
            value: datos.herramientas || '',
            isFieldEmpty: !datos.herramientas,
            handleInputChange: handleInputChange,
            className: 'form-control-brief-editar'
        },
        {
            step: 20,
            label: "Tiene algún factor de inclusión laboral", 
            type: "select",
            name: "selectedFactor",
            value: selectedFactor|| '',
            options: [
                { value: 'Si', label: 'Sí' },
                { value: 'No', label: 'No' },
            ],
            isFieldEmpty: !selectedFactor,
            onChange: handleDropdownChange,
    
        }, 
        {
            step: 20,
            label: "¿Cual es su factor de inclusión laboral?", 
            name: "factor",
            value: datos.factor || '',
            isFieldEmpty: !datos.factor,
            isVisible: selectedFactor=== 'Si',
        }, 
        {
            step: 21,
            label: "Tiene algún compromiso con alguna región del pais", 
            type: "select",
            name: "selectedCompromiso",
            value: selectedCompromiso || '',
            options: [
                { value: 'Si', label: 'Sí' },
                { value: 'No', label: 'No' },
            ],
            isFieldEmpty: !selectedCompromiso,
            onChange: handleDropdownChange,
    
        }, 
        {
            step: 21,
            label: "Selecciona la region con cual estas comprometida", 
            name: "id_region_con_compromiso",
            value: datos.id_region_con_compromiso || '',
            isFieldEmpty: !datos.id_region_con_compromiso,
            isVisible: selectedCompromiso === 'Si',
            type: "select",
            options: datosRegion.map((region) => ({
                value: region.id,
                label: region.nombre
            })),
            onChange: handleDropdownChange,
        }, 
        {
            step: 22,
            label: "Se identifica con algún pueblo originario", 
            type: "select",
            name: "pueblo",
            value: selectedPueblo || '',
            options: [
                { value: 'Si', label: 'Sí' },
                { value: 'No', label: 'No' },
            ],
            isFieldEmpty: !selectedPueblo,
            onChange: handleDropdownChange,
    
        }, 
        {
            step: 22,
            label: "Con que pueblo originario se identifica?", 
            name: "nombrePuebloOriginario",
            value: datos.nombrePuebloOriginario || '',
            isFieldEmpty: !datos.nombrePuebloOriginario,
            isVisible: selectedPueblo === 'Si',
        }, 
        {
            step: 23,
            label: "Perfil de linkedin", 
            description: "Puede ser el perfil de RRSS que quieras compartir con nosotros.",
            name: "redesSociales",
            value: datos.redesSociales || '',
            isFieldEmpty: !datos.redesSociales,
            onChange: handleInputChange,
        },
        {
            step: 24,
            label: "Respuesta formulario de personalidad", 
            description: "https://www.humanmetrics.com/personalidad/test", 
            type: "select",
            name: "id_personalidad",
            value: datos.id_personalidad || '',
            options: personalidades.map((personalidad) => ({
                value: personalidad.id,
                label: personalidad.personalidad
            })),
            isFieldEmpty: !datos.id_personalidad,
            onChange: handleDropdownChange,
    
        },
        {
            step: 25,
            label: "Nombre contacto de referencia",
            name: "contacto_nombre1",
            value: datos.contacto_nombre1 || '',
            isFieldEmpty: !datos.contacto_nombre1,
            // isVisible: datos.contacto_validacion === '1',
            onChange: handleContactos,
        }, 
        {
            step: 25,
            label: "Celular contacto de referencia",
            name: "contacto_celular1",
            value: datos.contacto_celular1 || '',
            isFieldEmpty: !datos.contacto_celular1,
            // isVisible: datos.contacto_validacion === '1',
            onChange: handleContactos,
        },
        {
            step: 25,
            label: "Email contacto de referencia",
            name: "contacto_mail1",
            value: datos.contacto_mail1 || '',
            isFieldEmpty: !datos.contacto_mail1,
            // isVisible: datos.contacto_validacion === '1',
            onChange: handleContactos,
        }, 
        {
            step: 26,
            label: "Nombre contacto de referencia",
            name: "contacto_nombre2",
            value: datos.contacto_nombre2 || '',
            isFieldEmpty: !datos.contacto_nombre2,
            // isVisible: datos.contacto_validacion2 === '1',
            onChange: handleContactos,
        },
        {
            step: 26,
            label: "Celular contacto de referencia",
            name: "contacto_celular2",
            value: datos.contacto_celular2 || '',
            isFieldEmpty: !datos.contacto_celular2,
            // isVisible: datos.contacto_validacion2 === '1',
            onChange: handleContactos,
        },
        {
            step: 26,
            label: "Email segundo contacto de referencia",
            name: "contacto_mail2",
            value: datos.contacto_mail2 || '',
            isFieldEmpty: !datos.contacto_mail2,
            // isVisible: datos.contacto_validacion2 === '1',
            onChange: handleContactos,
        }
      ]

    return (
    <div className='editar-container'>
    <img src={cityImage} alt="City Background" className="image-city-edit" />
    {/* <div className='fondo_rosado_editar'></div> */}
    <div className='fondo_blanco_editar'></div>
    <div className='container-editar'>
    <h3 className='message-forgot-password'>Editar Información</h3>
    <div className='questions'>
        {questions
            .filter(question => question.step === currentStep)
            .map(question => (
                <Question
                    key={question.name}
                    label={question.label}
                    description={question.description}
                    name={question.name}
                    value={question.value}
                    onChange={question.onChange || handleInputChange}
                    isFieldEmpty={question.isFieldEmpty}
                    isValid={question.isValid}
                    invalidMessage={question.invalidMessage}
                    type={question.type || 'text'}
                    options={question.options || []}
                    isVisible={question.isVisible !== undefined ? question.isVisible : true}
                    codigo={question.codigo} 
                    placeholder={question.placeholder}
                    handleFile={question.handleFile}
                    className={question.className}
                    containerClassName = 'form-group-editar'
                    negritaClassName = 'label-negrita-editar'
                    grisClassName='label-gris-editar'
                />
            ))
        }
        <div className='button-container-editar' >
        <BackButton handleBack={step.onCancel} buttonText="Cancelar" buttonColor="#5A5A5A" />
        <NextButton handleNext={handleSubmit} buttonText="Enviar" buttonColor="#e6007e" />
        </div>
        
        
    </div>
    </div>
    {/* Nuevo contenedor para los botones */}
    {/* <div className='button-container-editar'>  */}
        {/*<BackButton handleBack={step.onCancel} buttonText="Cancelar" buttonColor="#5A5A5A" marginLeft="-10%" marginTop="-9.4%"  />*/}
        {/*<NextButton handleNext={handleSubmit} buttonText="Enviar" buttonColor="#e6007e" marginLeft="15%" marginTop="-12%" />*/}
    {/*</div> */}
</div>

        
    );
};

export default Edit;
